html,
body {
  font-size: 16px;
}

h1 {
  font-size: 2rem !important;
}
h2 {
  font-size: 1.6rem !important;
}
h3 {
  font-size: 1.3rem !important;
}
